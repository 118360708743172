<template>
  <div class="page-applications container">
    <div class="applications-wrapper">
      <div class="ui active inverted dimmer page-loader" v-if="!waitingForDocumentsDealsList || !ReviewingYourDetailsDealsList">
        <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
      </div>
      <div class="ui small info message" v-else-if="!ReviewingYourDetailsDealsList.length && !waitingForDocumentsDealsList.length">No applications here</div>
      <template v-else>
        <div class="deals m-b-20" v-if="ReviewingYourDetailsDealsList.length">
          <div class="ui link link-filter">
            <tenant-text name="ReviewingYourDetails" />
          </div>
          <div class="ui stackable cards">
            <ProductItem :product="deal" v-for="deal in ReviewingYourDetailsDealsList" :key="deal.opportunityApplicationId" />
          </div>
        </div>
        <div class="bookmarks m-b-20" v-if="waitingForDocumentsDealsList.length">
          <div class="ui link link-filter m-t-2">Awaiting for documents</div>
          <div class="ui stackable cards">
            <ProductItem :product="bookmark" v-for="bookmark in waitingForDocumentsDealsList" :key="bookmark.bookmarkId" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { getApplicationListByStatus } from '@/api/opportunityApplication'
import ProductItem from '@/components/ProductItem'
import Spinner from '@/components/Spinner'

export default {
  name: 'FundingApplications',
  components: {
    ProductItem,
    Spinner
  },
  data() {
    return {
      ReviewingYourDetailsDealsList: null,
      waitingForDocumentsDealsList: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    })
  },
  methods: {
    async init() {
      await this.requestReviewingYourDetailsDeals()
      await this.requestWaitingForDocumentsDeals()
    },

    requestReviewingYourDetailsDeals() {
      return getApplicationListByStatus({ companyId: this.companyId, status: 'ReviewingYourDetails' })
        .then(res => {
          this.ReviewingYourDetailsDealsList = res.data.sort((a, b) => b.maxSize - a.maxSize)
        })
        .catch(e => {
          this.ReviewingYourDetailsDealsList = []
        })
    },
    requestWaitingForDocumentsDeals() {
      return getApplicationListByStatus({ companyId: this.companyId, status: 'InformationRequested' })
        .then(res => {
          this.waitingForDocumentsDealsList = res.data.sort((a, b) => b.maxSize - a.maxSize)
        })
        .catch(e => {
          this.waitingForDocumentsDealsList = []
        })
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/swoop/variables';

.page-applications {
  flex-grow: 1;
  height: 100%;
}

.container {
  margin-left: 0;
}

.applications-wrapper {
  padding: 2rem 0;
  @media only screen and (max-width: $breakpoint-md-max) {
    padding: 1rem;
  }
  .link-filter {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: $color-link;
    border: none;
  }

  .ui.cards {
    .card {
      background-color: #fff;
      border-radius: 12px;
      box-shadow: 0 2px 12px 0 rgba(51, 51, 79, 0.06);
    }
    &.stackable {
      display: grid;
      grid-row-gap: 16px;
      row-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(auto, auto));
      @media only screen and (min-width: $breakpoint-sm-max) {
        grid-template-columns: repeat(auto-fill, minmax(300px, auto));
      }
      @media only screen and (min-width: $breakpoint-lg-max) {
        grid-template-columns: repeat(auto-fill, minmax(376px, auto));
      }
      .card {
        @media only screen and (max-width: $breakpoint-sm-max) {
          width: 100%;
        }
        @media only screen and (min-width: $breakpoint-sm-max) {
          width: 300px;
        }
        @media only screen and (min-width: $breakpoint-lg-max) {
          width: 376px;
        }
      }
    }
  }
  .ui.link {
    cursor: pointer;
    color: var(--color-primary-500);
    &:hover {
      color: $color-link-hover;
    }

    @media only screen and (max-width: $breakpoint-md-max) {
      padding-bottom: 1rem;
    }
  }

  @media only screen and (max-width: $breakpoint-sm-max) {
    padding: 1rem;
  }
}
</style>
